<template>
    <div>
        <div class="customerForm">
            <div class="mt-5">
                <div class="form-header-wrap">
                    <h2 class="doFormTitle">Delivery Order Form</h2>
                    <div class="flex flex-column align-start">
                        <div>
                            <span class="doFormSubTitle">Delivery Order for Import FCL only (Export & LCL options are coming soon)</span>
                        </div>
                        <div>
                            <span class="doFormSubTitle">
                                Fields with marked <span class="text-red">*</span> are required
                            </span>
                        </div>
                    </div>
                </div>

                <v-form ref="form" v-model="valid" width="960">
                    <div class="px-6">
                        <div class="mt-5 mb-5">
                            <label class="text-item-label">Delivery Order Document <span class="text-red">*</span></label>
                            <div :class="this.fields.documents.length > 0 ? 'd-none' : ''" class="flex flex-row flex-wrap">
                                <div
                                    style="display: block;width:100%"
                                    class="h-screen items-left justify-left text-left"
                                    id="app"
                                >
                                    <div
                                        style="width: 100%"
                                        @dragover="dragover"
                                        @dragleave="dragleave"
                                        @drop="dropFile"
                                    >
                                        <input
                                            style="display: none"
                                            type="file"
                                            name="fields[]"
                                            id="screenshots"
                                            class="w-px h-px opacity-0 overflow-hidden absolute"
                                            ref="file"
                                            accept="application/pdf"
                                            @change="onChange()"
                                        />
                                        <label
                                            for="screenshots"
                                            class="block cursor-pointer triggerelement"
                                        >
                                            <div class="flex">
                                                <div class="drop-field">
                                                    <div class="browser-text text-center">
                                                        <span class="">Browse or Drop File here</span>
                                                    </div>
                                                    <div class="btn-white2 browser-btn">
                                                        <div class="upload-btn mt-2">
                                                        <span class="iconUploadfile"
                                                            ><i class="ic-upload"></i
                                                        ></span>
                                                        <span class="classUpload">Upload</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <span style="color:red;font-size:12px" v-if="documentErrorMsg !=='' && fields.documents.length == 0">{{documentErrorMsg}}</span>
                            <div
                                class="flex w-full h-screen items-left justify-left text-left"
                                style="width: 100%"
                            >
                                <ul
                                    class="displayItem flex flex-column mt-4"
                                    v-if="this.fields.documents.length"
                                    v-cloak
                                >
                                    <li
                                        class="text-sm p-1 mb-3"
                                        style="width: 100%"
                                        v-for="(file, index) in this.fields.documents"
                                        :key="index"
                                    >
                                        <div
                                            style="display: flex; align-items: center"
                                            class="rowItemUploaded flex justify-lg-space-between"
                                        >
                                            <div class="fileNameList" style="top: 0px !important">
                                                <span
                                                    class="IconUpload"
                                                    style="background: #fff; border-radius: 30px"
                                                >
                                                    <img
                                                        class="imgIcon"
                                                        src="../../assets/icons/documentFile-white.svg"
                                                    />
                                                </span>
                                                {{ file.name }}
                                            </div>
                                            <div
                                                style="display: flex; align-items: center"
                                                class="mainwrapitem"
                                            >
                                                <div
                                                    class="flex justify-end fileSize"
                                                    v-if="uploadCompleted"
                                                >
                                                    <button type="button" class="replace-btn" @click="handleReplace">
                                                        Replace
                                                    </button>
                                                    <a
                                                        class="ml-2 document-btn"
                                                        target="_blank"
                                                        :href="getFileURL(file)"
                                                    >
                                                        <img
                                                            style="cursor: pointer;margin-right: 12px;margin-top: 9px;"
                                                            src="../../assets/icons/view-blue.svg"
                                                        />
                                                    </a>
                                                    <button
                                                        class="ml-2 document-btn"
                                                        type="button"
                                                        @click="removeFile(fields.documents.indexOf(file))"
                                                        title="Remove file"
                                                    >
                                                        <i style="color: red" class="ic-delete"> </i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <small style="color: #f93131 !important" v-if="moreFilesError">Only 1 file can be uploaded</small>
                                </ul>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-6 pa-0">
                                <div class="flex flex-column">
                                    <div class="col-md-12">
                                        <label class="text-item-label">Company Name <span class="text-red">*</span></label>
                                        <v-text-field
                                            type="text"
                                            placeholder="Company Name"
                                            outlined
                                            class="text-fields"
                                            v-model="fields.company_name"
                                            :rules="fieldrequired"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="text-item-label">Delivery Contacts <span class="text-red">*</span></label>
                                <div class="tags-email-wrapper mb-1">
                                    <vue-tags-input
                                        hide-details="auto"
                                        v-model="dc"
                                        :tags="delivery_contacts"
                                        :add-on-blur="true"
                                        class="text-fields"
                                        :add-on-key="[13, ',']"
                                        placeholder="e.g example@domain.com"
                                        :validation="
                                            delivery_contacts.length > 0 ? tagsValidation : tagsValidation
                                        "
                                        @tags-changed="newTags => delivery_contacts = newTags"
                                    />
                                </div>
                                <small class="text-red" v-if="errorMsg !== '' && !fields.delivery_contacts.length > 0">{{ errorMsg }}</small>
                            </div>

                        </div>
                        <div class="row">
                               <div class="col-md-6 pt-0">
                                        <label class="text-item-label">My Reference Numbers</label>
                                        <div class="tags-email-wrapper mb-1">
                                            <vue-tags-input
                                                hide-details="auto"
                                                v-model="crn"
                                                :tags="crn_tags"
                                                :add-on-blur="true"
                                                class="text-fields"
                                                :add-on-key="[13, ',']"
                                                placeholder="Enter refrence #"
                                                @tags-changed="newTags => crn_tags = newTags"
                                            />
                                        </div>
                                    </div>
                                <div class="col-md-6 pt-0">
                                    <label class="text-item-label">Receiving Location Contact</label>
                                    <v-text-field
                                        type="text"
                                        placeholder="Enter phone number"
                                        outlined
                                        class="text-fields"
                                        v-model="fields.receiving_location_contact"
                                        :rules="phoneNumberValidation"
                                    />
                                </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col-md-12 pb-0">
                                <label class="text-item-label">Mode</label>
                                <div class="row">
                                    <div class="col-md-2 pr-0">
                                        <div class="radio-box pl-5">
                                            <input type="radio" v-model="fields.mode" value="ocean" name="radio" class="radio-wrap"/>
                                            <div class="control__indicator"></div>
                                            <span class="ml-5  radio-text">
                                                <label class="ml-2">Ocean</label>
                                                <span class="radio-icon">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.80651 3.75H3.26319C2.98704 3.75 2.76319 3.97386 2.76319 4.25V5.45587C2.76319 5.73201 2.98704 5.95587 3.26319 5.95587H8.80651C9.08265 5.95587 9.30651 5.73201 9.30651 5.45587V4.25C9.30651 3.97386 9.08265 3.75 8.80651 3.75ZM10.7214 7.92646C10.7214 7.65031 10.4976 7.42646 10.2214 7.42646H1.80902C1.53287 7.42646 1.30902 7.65031 1.30902 7.92646V9.13233C1.30902 9.40847 1.53287 9.63233 1.80902 9.63233H10.2214C10.4976 9.63233 10.7214 9.40847 10.7214 9.13233V7.92646ZM14.6855 8.36547L12.5771 11.0675H0.500262C0.173128 11.0675 -0.0658836 11.3764 0.0162948 11.6931L1.10178 15.8756C1.159 16.0961 1.35798 16.25 1.58575 16.25H17.024C17.2349 16.25 17.4231 16.1177 17.4945 15.9192L19.9703 9.03467C20.0874 8.70887 19.846 8.36547 19.4998 8.36547H14.6855ZM4.21725 13.3089C4.21725 13.715 3.89175 14.0442 3.49021 14.0442C3.08868 14.0442 2.76318 13.715 2.76318 13.3089C2.76318 12.9028 3.08868 12.5736 3.49021 12.5736C3.89175 12.5736 4.21725 12.9028 4.21725 13.3089ZM7.85264 14.0442C8.25417 14.0442 8.57968 13.715 8.57968 13.3089C8.57968 12.9028 8.25417 12.5736 7.85264 12.5736C7.45111 12.5736 7.12561 12.9028 7.12561 13.3089C7.12561 13.715 7.45111 14.0442 7.85264 14.0442ZM12.9417 13.3089C12.9417 13.715 12.6162 14.0442 12.2147 14.0442C11.8132 14.0442 11.4877 13.715 11.4877 13.3089C11.4877 12.9028 11.8132 12.5736 12.2147 12.5736C12.6162 12.5736 12.9417 12.9028 12.9417 13.3089Z" fill="#6D858F"/>
                                                    </svg>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="radio-box pl-6">
                                            <input type="radio" v-model="fields.mode" value="air" name="radio" class="radio-wrap"/>
                                            <div class="control__indicator"></div>
                                            <span class="ml-5  radio-text">
                                                <label class="ml-2">Air</label>
                                                <span class="radio-icon">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M16.0265 7.23006L12.5327 7.58131L8.76828 1.54844C8.65978 1.38176 8.51326 1.24471 8.34142 1.14914C8.16957 1.05358 7.97758 1.00238 7.78208 1H6.57939C6.39282 1.00521 6.20999 1.05484 6.04537 1.14497C5.88076 1.2351 5.73887 1.36326 5.63094 1.5193C5.52301 1.67533 5.452 1.85497 5.42353 2.04399C5.39506 2.23301 5.40991 2.42623 5.4669 2.60835L7.38519 8.1051L3.96955 8.45019C1.81073 6.22561 0.818508 7.03903 1.02898 7.77234C1.84681 10.57 1.84681 9.43615 1.02898 12.2338C0.812495 12.9795 1.81073 13.7744 3.96354 11.5683L7.37918 11.9134L5.4669 17.3978C5.41116 17.5791 5.39708 17.7711 5.42576 17.9589C5.45445 18.1466 5.52512 18.3251 5.63226 18.4802C5.73939 18.6353 5.88009 18.763 6.04335 18.8532C6.20662 18.9433 6.38801 18.9936 6.57337 19H7.77606C7.97157 18.9976 8.16356 18.9464 8.33541 18.8509C8.50725 18.7553 8.65377 18.6182 8.76227 18.4516L12.5327 12.468L16.0265 12.7761C16.4033 12.8125 16.7834 12.768 17.1424 12.6452C17.5014 12.5224 17.8314 12.3241 18.1114 12.0631C18.3913 11.802 18.6149 11.484 18.7679 11.1292C18.921 10.7745 19 10.3909 19 10.0031C19 9.61524 18.921 9.23167 18.7679 8.87693C18.6149 8.5222 18.3913 8.20413 18.1114 7.94308C17.8314 7.68204 17.5014 7.48378 17.1424 7.36099C16.7834 7.23821 16.4033 7.19361 16.0265 7.23006Z" fill="#6D858F"/>
                                                    </svg>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <div class="row mt-1">
                            <div class="col-md-6 pb-0 selectBox">
                                <label class="text-item-label">Carrier</label>
                                <v-autocomplete
                                    class="text-fields carrieerTextfield"
                                    :items="carriers"
                                    item-text="name"
                                    item-value="id"
                                    placeholder="Select Carrier"
                                    solo
                                    append-icon="mdi-chevron-down"
                                    v-model="fields.carrier"

                                >
                                </v-autocomplete>
                            </div>
                            <div class="col-md-6 pb-0 selectBox">
                                <label class="text-item-label">Vessel</label>
                                <v-text-field
                                    type="text"
                                    placeholder="Vessel"
                                    outlined
                                    class="text-fields"
                                    v-model="fields.vessel"
                                />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6 pb-0">
                                <label class="text-item-label">MBL/AWB Number <span class="text-red"></span></label>
                                <v-text-field
                                    type="text"
                                    placeholder="MBL/AWL Number"
                                    outlined
                                    class="text-fields"
                                    v-model="fields.mbl_number"
                                />
                            </div>
                            <div class="col-md-6 pb-0">
                                <label class="text-item-label">Expected Time of Arrival <span class="text-red">*</span></label>
                                <v-text-field
                                    type="text"
                                    placeholder="Select ETA Date"
                                    outlined
                                    class="text-fields expectedTimeArrival"
                                    append-icon="ic-calendar"
                                    v-model="fields.eta"
                                    :rules="dateValidation"
                                    @click="pickerLabel = 'Pick ETA Date'; isDatePickerOpen = true;"
                                />
                            </div>
                        </div>

                        <div class="row mt-5">
                            <div class="col-md-6 pb-0">
                                <label class="text-item-label">Pickup Location</label>
                                <v-autocomplete
                                v-model="terminal"
                                :items="theterminals"
                                placeholder="Terminal"
                                item-text="name"
                                item-value="id"
                                solo
                                append-icon="mdi-chevron-down"
                                name="custom_notes"
                            ></v-autocomplete>
                            </div>
                            <div class="col-md-6 pb-0">
                                <label class="text-item-label">Delivery Location</label>
                                <!-- <textarea
                                    rows="5"
                                    class="textarea-field"
                                    placeholder="Delivery Location"
                                    v-model="fields.delivery_location"
                                >
                                </textarea> -->
                                <v-autocomplete
                                :loading="loadingLocationPlaces"
                                v-model="fields.delivery_location"
                                :items="places"
                                :search-input.sync="searchPlace"
                                placeholder="Type Delivery Addresss"
                                item-text="place_name"
                                no-filter
                                clearable
                                hide-no-data
                                @click:clear="clearSearchPlaces"
                                @change="changeDeliveryLocationState"
                                solo
                                full-width
                                append-icon="mdi-chevron-down"
                                name="custom_notes"
                            ></v-autocomplete>
                            </div>
                        </div>

                        <div class="row mt-5">
                            <div class="col-md-6 pb-0">
                                <label class="text-item-label">Receiving Location Days</label>
                                <v-select
                                    ref="Vueselect"
                                    :items="openDays"
                                    class="select-activity text-fields shrink receivingdays"
                                    item-text="activity"
                                    item-value="activity"
                                    outlined
                                    placeholder="Customer Actvity"
                                    chips
                                    attach
                                    append-icon="mdi-chevron-down"
                                    :background-color="'white'"
                                    hide-details="auto"
                                    :menu-props="{ contentClass: '', bottom: true, offsetY: true, maxHeight: 500,maxWidth:500}"
                                    multiple
                                    v-model="fields.receiving_location_days"
                                >
                                    <template v-slot:selection="{ item }">
                                        <v-chip class="v-chip--select">
                                            {{ item.substring(0, 3) }},
                                        </v-chip>
                                    </template>
                                    <template v-slot:item="{ item, on, attrs }">
                                        <v-list-item class="d-flex justify-start" style="border:none;min-height:45px;height:45px;" v-on="on" v-bind="attrs" #default="{ active }">
                                            <div><v-checkbox :input-value="active"></v-checkbox></div>
                                            <div>
                                                <p class="name mt-9 font-regular" style="color: #4A4A4A;"> {{ item }} </p>
                                            </div>
                                        </v-list-item>
                                    </template>
                                </v-select>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-6 pb-0">
                                        <label class="text-item-label">Receiving Opens</label>
                                        <v-text-field
                                            class="text-fields receivingOpens"
                                            outlined
                                            type="text"
                                            hide-details="auto"
                                            v-model="receivingTimeFromText"
                                            placeholder="Set Time"
                                            append-icon="mdi-clock-time-four-outline"
                                            @change="handleTimeChange(receivingTimeFromText,'from')"
                                            dense
                                        ></v-text-field>
                                        <!-- <v-dialog
                                            ref="field_from"
                                            v-model="modal_ps_time"
                                            :return-value.sync="fields.receiving_time.from"
                                            persistent
                                            width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    class="text-fields receivingOpens"
                                                    hide-details
                                                    v-model="fields.receiving_time.from"
                                                    placeholder="Set Time"
                                                    append-icon="mdi-clock-time-four-outline"
                                                    readonly
                                                    outlined
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :rules="timeValidation"
                                                ></v-text-field>
                                            </template>
                                            <v-time-picker
                                                v-if="modal_ps_time"
                                                v-model="fields.receiving_time.from"
                                                full-width
                                            >
                                                <v-btn
                                                    v-show="fields.receiving_time.from"
                                                    text
                                                    color="warning"
                                                    class="mr-3 ml-0"
                                                    @click="$refs.field_from.save('')"
                                                >
                                                    Remove
                                                </v-btn>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="modal_ps_time = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="
                                                        $refs.field_from.save(
                                                        fields.receiving_time.from
                                                    )
                                                    "
                                                >
                                                    OK
                                                </v-btn>
                                            </v-time-picker>
                                        </v-dialog> -->
                                    </div>
                                    <div class="col-md-6 pb-0">
                                        <label class="text-item-label">Receiving Closes</label>
                                        <v-text-field
                                            class="text-fields receivingOpens"
                                            outlined
                                            type="text"
                                            hide-details="auto"
                                            v-model="receivingTimeToText"
                                            placeholder="Set Time"
                                            append-icon="mdi-clock-time-four-outline"
                                            @change="handleTimeChange(receivingTimeToText,'to')"
                                            dense
                                        ></v-text-field>
                                        <!-- <v-dialog
                                            ref="field_to"
                                            v-model="modal_closing_time"
                                            :return-value.sync="fields.receiving_time.to"
                                            persistent
                                            width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    class="text-fields receivingOpens"
                                                    hide-details
                                                    v-model="fields.receiving_time.to"
                                                    placeholder="Set Time"
                                                    append-icon="mdi-clock-time-four-outline"
                                                    readonly
                                                    outlined
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :rules="timeValidation"
                                                ></v-text-field>
                                            </template>
                                            <v-time-picker
                                                v-if="modal_closing_time"
                                                v-model="fields.receiving_time.to"
                                                full-width
                                            >
                                                <v-btn
                                                    v-show="fields.receiving_time.to"
                                                    text
                                                    color="warning"
                                                    class="mr-3 ml-0"
                                                    @click="$refs.field_to.save('')"
                                                >
                                                    Remove
                                                </v-btn>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="modal_ps_time = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="
                                                        $refs.field_to.save(
                                                        fields.receiving_time.to
                                                    )
                                                    "
                                                >
                                                    OK
                                                </v-btn>
                                            </v-time-picker>
                                        </v-dialog> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-md-12">
                                <label class="text-item-label">Description of Articles, Special Marks & Exceptions</label>
                                <textarea
                                    rows="5"
                                    style="resize:none;"
                                    class="textarea-field"
                                    placeholder="Description of Articles, Special Marks & Exceptions"
                                    v-model="fields.description"
                                >
                                </textarea>
                            </div>
                        </div>
                    </div>
                </v-form>

                <v-form v-model="containerValid" lazy-validation ref="containerForm">

                    <div class="row mt-5">
                        <div class="col-md-12 px-9">
                            <p style="color:red" v-if="errorlfd">LFD must be later than ETA.</p>
                            <v-data-table
                                class="add-containers-table customerform_addContainers"
                                :headers="headers"
                                :items="fields.containers"
                                :items-per-page="5"
                                hide-default-footer
                                dense
                            >
                                <template v-slot:item="{ item,index }">
                                    <!-- <tbody> -->
                                        <!-- <tr v-for="(item, index) in items" :key="index">
                                            <td class="text-start">
                                                {{ item.container_number }}
                                            </td>
                                            <td class="text-end">
                                                {{ item.container_size }}
                                            </td>
                                            <td class="text-end">
                                                {{ item.container_weight }}
                                            </td>
                                            <td class="text-end">
                                                {{ item.container_volume }}
                                            </td>
                                            <td class="text-end">
                                                {{item.container_qty}} -->
                                              <!-- <input type="text" v-model="item.container_qty" />   -->
                                            <!-- </td>
                                            <td class="text-end">
                                                {{item.lfd}} -->
                                              <!-- <input type="text" v-model="item.lfd"  @click=" {
                                                            pickerLabel = 'Pick LFD Date'
                                                            pickerEdit=1
                                                            isDatePickerOpen = true
                                                            currentindex = index
                                                        }" />                                                -->
                                            <!-- </td> -->
                                            <!-- <td class="text-center">
                                                <span @click="removeContainer(index)" style="color: red; cursor: pointer;">X</span>
                                            </td> -->
                                        <!-- </tr> -->
                                        <tr>
                                                <td  class="text-start" style="border:1px solid #EBF2F5;width:200px">
                                                    <v-text-field
                                                        type="text"
                                                        dense
                                                        hide-details="auto"
                                                        placeholder="Enter Container Number"
                                                        class="text-fields container-nbr-ph" flat solo
                                                        v-model="item.container_number"
                                                        validate-on-blur
                                                        @input="inputText"
                                                        :rules="[checkforErrors(item,index)]"
                                                    />
                                                    
                                                    
                                                    <!-- {{checkforErrors(item,index)}} -->
                                                </td>
                                                <td class="text-end" style="border:1px solid #EBF2F5;border-left:none;">
                                                    <v-autocomplete
                                                        v-model="item.container_size"
                                                        :items="sizes"
                                                        placeholder="Enter Size"
                                                        item-text="name"
                                                        item-value="id"
                                                        class="text-fields container-size"
                                                        append-icon="mdi-chevron-down"
                                                        no-filter
                                                        dense
                                                        hide-details="auto"
                                                        flat
                                                        clearable
                                                        hide-no-data
                                                        @click:clear="clearSearchPlaces"
                                                        full-width
                                                    ></v-autocomplete>
                                                </td>
                                                <td class="text-end" style="border:1px solid #EBF2F5;border-left:none;">
                                                    <v-text-field flat solo
                                                        type="number"
                                                        placeholder="Enter Weight"
                                                        class="text-fields inlinefield"
                                                        dense
                                                        hide-details="auto"
                                                        full-width
                                                        v-model="item.container_weight"
                                                    >
                                                        <template slot="append">
                                                            <p style="font-size: 14px;color: #819FB2;" class="mt-1">KG</p>
                                                        </template>
                                                    </v-text-field>
                                                </td>
                                                <td class="text-end" style="border:1px solid #EBF2F5;border-left:none">
                                                    <v-text-field flat solo
                                                        type="number"
                                                        placeholder="Enter Volume"
                                                        class="text-fields inlinefield"
                                                        full-width
                                                        dense
                                                        hide-details="auto"
                                                        v-model="item.container_volume"
                                                    >
                                                        <template slot="append">
                                                            <p style="font-size: 14px;color: #819FB2;" class="mt-1">CBM</p>
                                                        </template>
                                                    </v-text-field>
                                                </td>
                                                <td class="text-end" style="border:1px solid #EBF2F5;border-left:none">
                                                    <v-text-field flat solo
                                                        type="number"
                                                        placeholder="Enter Quantity"
                                                        class="text-fields"
                                                        dense
                                                        hide-details="auto"
                                                        full-width
                                                        v-model="item.container_qty"
                                                    />
                                                </td>
                                                <td class="text-end" style="border:1px solid #EBF2F5;border-left:none">
                                                    <!-- <v-autocomplete
                                                        :loading="loadingLocationPlaces"
                                                        v-model="containers.delivery_to"
                                                        :items="places"
                                                        :search-input.sync="searchPlace"
                                                        placeholder="Type Delivery Addresss"
                                                        item-text="place_name"
                                                        class="text-fields"
                                                        no-filter
                                                        clearable
                                                        hide-no-data
                                                        :rules="rules"
                                                        @click:clear="clearSearchPlaces"
                                                        filled
                                                        full-width
                                                        name="custom_notes"
                                                    ></v-autocomplete> -->
                                                    <v-text-field
                                                        solo flat
                                                        dense
                                                        hide-details="auto"
                                                        type="text"
                                                        style="display:flex ;align-item:center"
                                                        placeholder="Select LFD"
                                                        class="text-fields container-nbr-ph"
                                                        append-icon="ic-calendar"
                                                        v-model="item.lfd"
                                                        
                                                        @click=" {
                                                            pickerLabel = 'Pick LFD Date'
                                                            isDatePickerOpen = true
                                                            currentIndexOfLfd = index
                                                        }"
                                                    >
                                                    </v-text-field>

                                                </td>
                                                <td class="text-center" style="border:1px solid #EBF2F5;border-left:none">
                                                    <v-icon  size="19" :style="checkforFirstRowDataFunction(item) ? 'color:gray':'color:red'" @click="removeContainer(item,index)" style=" cursor: pointer;">mdi-close</v-icon>
                                                <!-- <span>X</span> -->
                                            </td>
                                            <!-- <td class="text-center">
                                                <span @click="handleAddContainer" class="add-btn">+</span>
                                            </td> -->
                                        </tr>
                                        <tr  v-if="checkforErrors(item,index) == false && checkforErrorsMsg(item.container_number,index) !==''">
                                            <td v-if="checkforErrors(item,index) == false" colspan="12">
                                            <span v-if="checkforErrors(item,index) == false" style="color:red;font-size:12px">{{checkforErrorsMsg(item.container_number,index)}}</span>
                                            </td>
                                        </tr>
                                        <tr v-else-if="!checked && checkingg(item,index) !== ''">
                                            <td colspan="12">
                                                <span style="color:red;font-size:12px">{{checkingg(item,index)}}</span>
                                            </td>
                                        </tr>
                                        
                                    <!-- </tbody> -->
                                </template>

                            </v-data-table>
                            <div style="width:200px;border:1px solid #EBF2F5">
                                <v-btn text class="btn-white" block style="border:none !important;justify-content:flex-start"  @click="handleAddContainer">
                                    <v-icon size="19">mdi-plus</v-icon> Add Container
                                </v-btn>
                                
                            </div>
                        </div>
                    </div>
                </v-form>

                <div class="form-footer-wrap mt-6">
                    <div class="flex flex-row">
                        <button @click="handleSubmit"  type="button" class="submit-btn">
                            Submit
                        </button>
                        <button @click="closenow" type="button" class="cancel-btn ml-2">
                            Close
                        </button>
                    </div>
                </div>
            </div>

            <DatePickerDialog
                :open="isDatePickerOpen"
                :multiple="false"
                :label="pickerLabel"
                @confirm="confirmDatePicker"
                @close="isDatePickerOpen = false"
            />
            <Containerrequired
                :dialogData.sync="dialogrequired"
                @close ="() => { dialogrequired = false }"
            />
        </div>
    </div>
</template>
<script>
import VueTagsInput from "@johmun/vue-tags-input";
import jQuery from 'jquery';
import DatePickerDialog from '../../components/Tables/Containers/Dialog/DatePickerDialog.vue';
import globalMethods from "../../utils/globalMethods";
import Containerrequired from "../Tables/DeliveryOrder/dialog/Onecontainerrequired.vue"
import axios from "axios";
import moment from 'moment';
import _ from 'lodash';

export default {
    name: "CustomerDeliveryOrderForm",
    props: ['trucker', 'carriers', 'sizes', 'hasSubmitted'],
    components: {
        VueTagsInput,
        DatePickerDialog,
        Containerrequired
    },
    data: () => ({
        errorlfd:0,
        terminal:'',
        theterminalitems:[],
        valid: true,
        containerValid: true,
        isAdding: false,
        errorFile: false,
        allFieldsValid: false,
        moreFilesError: false,
        uploadCompleted: true,
        isDatePickerOpen: false,
        modal_ps_time: false,
        modal_closing_time: false,
        pickerDefaultdata: "",
        currentstatepicker: false,
        dialogDatepicker: false,
        loadingLocationPlaces: false,

        searchPlace: "",
        searchPlacePickup:'',
        crn: "",
        dc: "",
        pickerLabel: "Pick ETA Date",
        currentindex:0,
        pickerEdit:0,
        errorMsg: "",
        currentIndexOfLfd:0,

        openDays:['Monday','Tuesday','Wednesday','Thursday','Friday'],
        headers: [
            {
                text: 'Container #',
                align: 'center',
                sortable: false,
                value: 'container_number',
            },
            {
                text: 'Size',
                align: 'end',
                sortable: false,
                value: 'size'
            },
            {
                text: 'Weight',
                align: 'end',
                sortable: false,
                value: 'weight'
            },
            {
                text: 'Volume',
                align: 'end',
                sortable: false,
                value: 'volume'
            },
            {
                text: 'Quantity',
                align: 'end',
                sortable: false,
                value: 'quantity'
            },
            {
                text: 'LFD',
                align: 'center',
                sortable: false,
                value: 'delivery_to'
            },
            // {
            //     text: 'Delivery Location',
            //     align: 'center',
            //     sortable: false,
            //     value: 'delivery_to'
            // },
            {
                text: '',
                sortable: false,
                value: ''
            },
        ],
        crn_tags: [],
        delivery_contacts: [],
        places: [],
        places_pickup: [],
        error: [],
        filelist: [],
        containers:{
            container_number: "",
            container_size: "",
            container_weight: "",
            container_volume: "",
            container_qty: "",
            lfd: "",
            // delivery_to: "",
            // deliveryLongitude: "",
            // deliveryLatitude: "",
        },

        rules: [(v) => !!v || "Input is required."],
        fieldrequired: [(v) => !!v || "This field is required"],
        container_number_rules: [
            (text) => {
                if (text == "") "Container # is required.";

                let msg = "The first 4 characters are alpha and followed by 7 digits.";
                if (text.length == 11) {
                    if (
                    /^[a-zA-Z]*$/.test(text.substring(0, 4)) &&
                    /^[0-9]*$/.test(text.substring(4, text.length))
                    ) {
                        return true;
                    }
                        return msg;
                }
                return "First 4 characters are alpha and followed by 7 digits.";
            },
        ],
        tagsValidation: [
            {
                classes: "t-new-tag-input-text-error",
                rule: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                disableAdd: true
            },
        ],
        dateValidation: [
            (date) => {
                if(date === "") {
                    return "This field is required"
                } else {
                    if(/^\d{4}-\d{2}-\d{2}$/.test(date)) {
                        return true
                    }

                    return "The date should be in YYYY-MM-DD format"
                }
            }
        ],
        timeValidation: [
            (time) => {
                if(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time)) {
                    return true
                }

                return "The time should be in HH:mm format"
            }
        ],
        phoneNumberValidation: [
            (number) => {
                if(number !== "") {
                    if(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(number)) {
                        return true
                    }

                    return "The phone number should be valid (xxx xxx xxxxx)"
                } else {
                    return true
                }
            }
        ],

        receivingTimeFromText: '',
        receivingTimeToText: '',

        fields: {
            documents: [],
            company_name: "",
            delivery_contacts: [],
            customer_ref_number: [],
            mode: "",
            carrier: "",
            carrier_name: "",
            vessel: "",
            mbl_number: "",
            eta: "",
            pickup_location: "",
            delivery_location: "",
            receiving_location_days: [],
            receiving_time: { from: "", to: ""},
            receiving_location_contact: "",
            description: "",
            containers: [
                    // {
                    //     container_number: "",
                    //     container_size: "",
                    //     container_weight: "",
                    //     container_volume: "",
                    //     container_qty: "",
                    //     lfd: ""
                        // delivery_to: "",
                        // deliveryLongitude: "",
                        // deliveryLatitude: "",
                    // }
                ],
            trucker: ""
        },
        documentErrorMsg:'',
        errorMsgForContainers:false,
        checked:true,
        dialogrequired:false

    }),
    async mounted(){
        // theterminalitems
        let that = this;
         await axios
            .get(`${process.env.VUE_APP_PO_URL}/getterminals`
            )
            .then(function (response) {
                if (response.data) {
                    console.log(response.data)
                  that.theterminalitems = response.data;
                }
            }).catch(error => {
                console.log(error)
            })
    },
    watch: {
        crn_tags(nv) {
            if(nv.length > 0) {
                let temp = []
                nv.map((obj) => {
                    temp.push({customer_ref_number: obj.text})
                })

                this.fields.customer_ref_number = temp
            } else this.fields.customer_ref_number = []
        },
        delivery_contacts(nv) {
            if(nv.length > 0) {
                let temp = []
                nv.map((obj) => {
                    temp.push(obj.text)

                    let uniq = [...new Set(temp)]
                    this.fields.delivery_contacts = uniq
                })
            } else this.fields.delivery_contacts = []
        },
        searchPlacePickup(val){
            if (val) {
                this.getPickupLocation(val);
            }
        },
        searchPlace(val) {
            if (val) {
                this.getDeliveryLocation(val);
            }
        },
        // "containers.container_number"() {
        //     let text = jQuery(".v-messages__message").html()
        //     this.notificationError(text)
        // },
        // "fields.carrier"(nv) {
        //     let carrier = this.carriers.find((carrier) => carrier.id === nv)
        //     this.fields.carrier_name = carrier.name
        // },
        hasSubmitted(nv) {
            if(!nv) {
                this.crn_tags = []
                this.delivery_contacts = []
                this.places = []
                this.places_pickup = [],
                this.error = []
                this.filelist = []
                this.containers = {
                    container_number: "",
                    container_size: "",
                    container_weight: "",
                    container_volume: "",
                    container_qty: "",
                    delivery_to: "",
                    deliveryLongitude: "",
                    deliveryLatitude: "",
                }

                this.fields = {
                    documents: [],
                    company_name: "",
                    delivery_contacts: [],
                    customer_ref_number: [],
                    mode: "",
                    carrier: "",
                    carrier_name: "",
                    vessel: "",
                    mbl_number: "",
                    eta: "",
                    pickup_location: "",
                    delivery_location: "",
                    receiving_location_days: [],
                    receiving_time: { from: "", to: ""},
                    receiving_location_contact: "",
                    description: "",
                    containers: [],
                    trucker: ""
                }
            }
        },
    },
    computed:{
        theterminals:{
            get(){
                return this.theterminalitems;
            }
        }
    },
    methods: {
        ...globalMethods,
        addContainer() {
            this.isAdding = true
        },
        dragover(event) {
            event.preventDefault();
            if (!event.currentTarget.classList.contains("bg-green-300")) {
                event.currentTarget.classList.remove("bg-gray-100");
                event.currentTarget.classList.add("bg-green-300");
            }
        },
        dragleave(event) {
            event.currentTarget.classList.add("bg-gray-100");
            event.currentTarget.classList.remove("bg-green-300");
        },
        dropFile(event) {
            event.preventDefault();
            this.$refs.file.files = event.dataTransfer.files;
            this.onChange();
        },
        closenow(){
            window.location.href= document.location.origin
        },
        onChange() {
            this.fields.documents = []
            this.filelist = [...this.$refs.file.files];
            let table = this.filelist;
            let errorCount = 0;
            let keys = [];

            Object.keys(table).forEach(function eachKey(key) {
                if (table[key].type != "application/pdf") {
                keys.push(key);
                errorCount++;
                }
            });

            if (errorCount > 0) {
                this.errorFile = 1;
                this.allFieldsValid = false;
            } else {
                this.errorFile = 0;
                this.allFieldsValid = true;
            }

            keys.forEach((e) => {
                this.filelist.splice(e, keys.length + 1);
            });

            if (this.filelist.length > 1) {
                this.filelist.splice(1, this.filelist.length);
                this.fields.documents.push(this.filelist[0])
                this.moreFilesError = true;
            } else {
                this.fields.documents.push(this.filelist[0])
                this.moreFilesError = false;
            }
        },
        removeFile(i) {
            this.fields.documents.splice(i, 1)
        },
        timetopass() {
            let finalEmailAddress =
                this.fields.delivery_contacts.length > 0
                ? this.options.map((o) => {
                    return o.text;
                    })
                : [];
            return finalEmailAddress;
        },
        timetopassreference() {
            let finalrefrenceNumber =
                this.optionsReferrence.length > 0
                ? this.optionsReferrence.map(({ text }) => ({
                    customer_ref_number: text,
                    }))
                : [];
            return JSON.stringify(finalrefrenceNumber);
        },
        handleReplace() {
            jQuery(document).find(".triggerelement").trigger("click");
        },
        getFileURL(file) {
            return URL.createObjectURL(file);
        },
        confirmDatePicker(date) {
            if(this.pickerLabel === "Pick ETA Date") {
                this.fields.eta = date
                //} else if(this.pickerEdit){
                //this.fields.containers[this.currentindex].lfd = date
                //this.pickerEdit = 0;
            }else{
                this.fields.containers[this.currentIndexOfLfd].lfd = date
            }
            this.isDatePickerOpen = false
        },
        showcustom(state, m) {
            this.pickerDefaultdata = state;
            this.currentstatepicker = m;
            this.dialogDatepicker = true;
        },
        async getDeliveryLocation(val) {
            if (val.length > 4) {
                this.loadingLocationPlaces = true;

                await fetch(`${process.env.VUE_APP_MAPBOX_PLACE_API_URL}/`+val+`.json?access_token=${process.env.VUE_APP_MAPBOX_PUBLIC_KEY}&country=US`)
                    .then((response) => response.json())
                    .then((json) => {
                        this.loadingLocationPlaces = false
                        this.places = [...json.features]
                    })
                    .catch((e) => {
                        this.loadingLocationPlaces = false;
                        console.log(e);
                    });
            }
        },
        async getPickupLocation(val) {
            if (val.length > 4) {
                this.loadingLocationPlaces = true;

                await fetch(`${process.env.VUE_APP_MAPBOX_PLACE_API_URL}/`+val+`.json?access_token=${process.env.VUE_APP_MAPBOX_PUBLIC_KEY}`)
                    .then((response) => response.json())
                    .then((json) => {
                        this.loadingLocationPlaces = false
                        this.places_pickup = [...json.features]
                    })
                    .catch((e) => {
                        this.loadingLocationPlaces = false;
                        console.log(e);
                    });
            }
        },
        changeDeliveryLocationState() {
            this.callApiForDeliveryLoc = !this.callApiForDeliveryLoc;
        },
        clearSearchPlaces() {
            this.places = [];
        },
        clearSearchPlacesPickup() {
            this.places_pickup = [];
        },
        handleAddContainer() {
            this.error = []
            let hasfoundlfderror = 0;

            Object.entries(this.fields.containers).forEach(([key, value]) => {
                if(value.container_number === "" ){
                    this.error.push(key)
                }else if(key=='lfd'){
                    let eta = new Date(this.fields.eta).getTime();
                    let lfd = new Date(value).getTime();
                    if (eta > lfd){
                        this.errorlfd = 1;
                        this.error.push(key)
                        hasfoundlfderror = 1;
                    }
                }
            })

            if(!hasfoundlfderror){
                this.errorlfd = 0;
            }

            if(!this.error.length > 0) {

                /**
                 * The Delivery to fields is changed into LFD field
                 */

                // const selectedPlace = this.places.find(
                //     (element) => element.place_name === this.containers.delivery_to
                // );

                // this.containers.deliveryLongitude = selectedPlace.geometry
                //     ? selectedPlace.geometry.coordinates[0]
                //     : null,
                // this.containers.deliveryLatitude = selectedPlace.geometry
                //     ? selectedPlace.geometry.coordinates[1]
                //     : null

                /**
                 * END
                 */

                if(this.$refs.containerForm.validate()) {
                    this.fields.containers.push(this.containers)
                    this.$nextTick(() => {
                          this.$refs.containerForm.resetValidation();
                    });
                    this.isAdding = false
                    this.containers = {
                        container_number: "",
                        container_size: "",
                        container_weight: "",
                        container_volume: "",
                        container_qty: "",
                        lfd: ""
                        // delivery_to: "",
                        // deliveryLongitude: "",
                        // deliveryLatitude: "",
                    }
                }
            }
        },
        removeContainer(item,i) {
            if(item.container_number !== '' && this.fields.containers.length == 1){
                return (item.container_number = '', item.container_size = '',item.container_weight = '',
            item.container_volume = '', item.container_qty = '',  item.lfd = '')
            }else if(item.container_number == '' && this.fields.containers.length == 1){
                return (item.container_number = '', item.container_size = '',item.container_weight = '',
            item.container_volume = '', item.container_qty = '',  item.lfd = '')
            }
            else{
                this.fields.containers.splice(i, 1)
                this.checked = true
            }
        },
        async handleSubmit() {
            if(this.fields.containers.length > 0){
                let result = this.fields.containers.every((val)=> {
                    if(val.container_number == ''){
                        return false
                    }
                    return true
                })
                if(!result) {
                    this.checked =false
                    return
                }else{
                    this.checked =true
                }
            }else{
                this.dialogrequired = true
            }
            if(!this.fields.delivery_contacts.length > 0) {
                this.errorMsg = "Please input atleast 1 delivery contact"
            }
            if(this.fields.documents.length == 0){
                this.documentErrorMsg = 'Please add document'
            }else{
                this.documentErrorMsg = ''
            }
            if(this.$refs.form.validate() && this.fields.delivery_contacts.length > 0 && this.fields.documents.length > 0 && this.$refs.containerForm.validate()) {
                let formData = new FormData();
                this.fields.pickup_location = this.terminal !== '' ? this.theterminals.find((e) => e.id == this.terminal).name :'';
                formData.append('flagCreatedFE',1);
                formData.append("xmbl", this.fields.mbl_number);
                //formData.append('xnote_mbl', this.fields.description)
                formData.append('container_commodity', this.fields.description)
                formData.append("xcontacts[]", this.fields.delivery_contacts);
                formData.append("xcarrier", this.fields.carrier);
                formData.append("xcarrier_name", this.fields.carrier_name);
                formData.append("xvessel", this.fields.vessel);
                formData.append("xreferrence[]", JSON.stringify(this.fields.customer_ref_number));
                formData.append("xcontainerList", JSON.stringify(this.fields.containers));
                formData.append('ETA', this.fields.eta)

                //formData.append('xlocation_at_name', this.fields.pickup_location)
                formData.append('xlocation_at', this.terminal)
                formData.append("location", "CF");
                formData.append('xcfcode',this.$route.params.id);

                formData.append('trucker', this.trucker.trucker_id)
                formData.append('xcompany_name', this.fields.company_name)
                formData.append('xmode', this.fields.mode)
                formData.append('xdelivery_location', this.fields.delivery_location)
                formData.append('xreceiving_location_days', JSON.stringify(this.fields.receiving_location_days))
                formData.append('xreceiving_time', JSON.stringify(this.fields.receiving_time))
                formData.append('xreceiving_location_contact', this.fields.receiving_location_contact)

                this.fields.documents.map((file, key) => {
                    formData.append(`files[${key}]`, file);
                });

                await axios
                .post(`${process.env.VUE_APP_PO_URL}/save-do-form`, formData)
                .then((response) => {
                    this.$emit('submitted', this.fields)
                    return response.data;
                })
                .catch((error) => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
                })
            }
        },

        handleTimeChange(value, field){
            if(value){
                let momentOb = moment(value, "hh:mm A");
                if (momentOb.isValid()) {
                    if(field == 'from'){
                        this.receivingTimeFromText = momentOb.format("hh:mm A");
                        this.fields.receiving_time.from = momentOb.format("HH:mm");
                    }else if (field == 'to'){
                        this.receivingTimeToText = momentOb.format("hh:mm A");
                        this.fields.receiving_time.to = momentOb.format("HH:mm");
                    }
                }else if(value[0] && value[0] > 2){
                    value = '0' + value;
                    momentOb = moment(value, "hh:mm A");
                    if (momentOb.isValid()) {
                        if(field == 'from'){
                        this.receivingTimeFromText = momentOb.format("hh:mm A");
                        this.fields.receiving_time.from = momentOb.format("HH:mm");
                        }else if (field == 'to'){
                            this.receivingTimeToText = momentOb.format("hh:mm A");
                            this.fields.receiving_time.to = momentOb.format("HH:mm");
                        }
                    }
                }
            }
        },

        timeRules(time = "") {
            time = time !== '' ? moment(time, "hh:mm A").format("HH:mm") : '' // format to 24 hr
            return {
                receivingTimeFrom: () => {
                    let msg = "";
                    if(_.isEmpty(time)) {
                        msg = "This field is required";
                    }else{
                        time = moment(time, "HH:mm", true)
                        if(time.isValid()) {
                            let to = moment(this.fields.receiving_time.to, "HH:mm", true)
                            if(moment(to).isBefore(time)){
                                msg = "Receiving Opens should not be later than Receiving Closes";
                            }else{
                                return true;
                            }
                        }
                        else {
                            msg = "Invalid Time ex. HH:mm";
                        }
                    }
                    return msg;
                },
                receivingTimeTo: () => {
                    let msg = "";
                    if(_.isEmpty(time)) {
                        msg = "This field is required";
                    }else{
                        time = moment(time, "HH:mm", true)
                        if(time.isValid()) {
                            let from = moment(this.fields.receiving_time.from, "HH:mm", true)
                            if(moment(time).isBefore(from)){
                                msg = "Receiving Closes should not be earlier than Receiving Opens";
                            }else{
                                return true;
                            }
                        }
                        else {
                            msg = "Invalid Time ex. HH:mm";
                        }
                    }
                    return msg;
                }
            }
        },
        checkforFirstRowDataFunction(item){
            if(item.container_number == '' && item.container_size == '' && item.container_weight == '' &&
            item.container_volume == '' && item.container_qty == '' && item.lfd == '' && this.fields.containers.length == 1 ){
                return true
            }
            else{
                return false
            }
        },
        inputText(){
            this.checked = true
        },
        checkingg(item){
            if(this.checked) return
            if(item.container_number == '' && this.fields.containers.length > 0){
                return 'Container # is required.'
            }else{
                return ''
            }
        },
        checkforErrors(item){
            let error = false
            if(this.fields.containers.length > 1){
                // let error1 = this.fields.containers.filter(val =>{
                //         if(val.container_number == ''){
                //             return val
                //         }
                //     })
                //     if(error1.length > 0){
                //         return false
                //     }
                if(item.container_number == ''){
                    
                    if(item.container_size == '' && item.container_weight == '' &&
                    item.container_volume == '' && item.container_qty == '' && item.lfd == ''){
                        return true
                    }
                    else{
                        return false
                    }
                }else{
                    // if(val.container_number !== ''){
                    if(item.container_number.length == 11){
                        if (
                            /^[a-zA-Z]*$/.test(item.container_number.substring(0, 4)) &&
                        /^[0-9]*$/.test(item.container_number.substring(4, item.container_number.length))
                        ) {
                            return true;
                        }
                    }else{
                        return false
                    }
                    // }
                }
            //    error =  this.fields.containers.every(val => {
            //     if(val.container_number !== ''){
            //         if(val.container_number.length == 11){
            //             if (
            //                 /^[a-zA-Z]*$/.test(item.container_number.substring(0, 4)) &&
            //             /^[0-9]*$/.test(item.container_number.substring(4, item.container_number.length))
            //             ) {
            //                 return true;
            //             }
            //         }else{
            //             return false
            //         }
            //     }
            //     return false
            //    })
            }else{
                if(item.container_number !== ''){
                    if (item.container_number.length == 11) {
                        if (
                            /^[a-zA-Z]*$/.test(item.container_number.substring(0, 4)) &&
                        /^[0-9]*$/.test(item.container_number.substring(4, item.container_number.length))
                        ) {
                            return true;
                        }
                            return false;
                    }
                }else if(item.container_number == '' && ( item.container_size == '' && item.container_weight == '' &&
            item.container_volume == '' && item.container_qty == '' && item.lfd == '')){
                    return true
                }
                else{
                    error = false
                }
            }
            if(error){
                this.errorMsgForContainers = true
                return true
            }else{
                this.errorMsgForContainers = false
                return false
            }
        },
        checkforErrorsMsg(text,index){
            if(this.fields.containers.length == 1 && text == ''){
                if(this.fields.containers[index].container_size !== '' || this.fields.containers[index].container_weight !== '' || 
                this.fields.containers[index].container_volume !== '' || this.fields.containers[index].container_qty !== '' || this.fields.containers[index].lfd !== ''){
                    return 'Container # is required.'
                }else{
                    return ''
                }
            } else{
                if (text == "") {
                    return "Container # is required.";
            } else{
                let msg = "The first 4 characters are alpha and followed by 7 digits.";
                if (text.length == 11) {
                    if (
                        /^[a-zA-Z]*$/.test(text.substring(0, 4)) &&
                    /^[0-9]*$/.test(text.substring(4, text.length))
                    ) {
                        return '';
                    }
                        return msg;
                }
                return "First 4 characters are alpha and followed by 7 digits.";
            }
        }
        }

    },

};
</script>
<style>
    .expectedTimeArrival .v-input__append-inner{
        position: relative;
        top:7px;
    }
    .receivingOpens .v-input__append-inner{
        position: relative;
        top:7px;
    }

    .customerform_addContainers .v-text-field {
        height: 37px !important
    }

    .customerform_addContainers .inlinefield  .v-input__append-inner{
        position: relative;
        top:7px;
    }

    .customerform_public .text-fields {
        padding-top: 0px;
        margin-top: 4px;
    }
    .container-nbr-ph.v-text-field.v-text-field--solo .v-input__control input {
        font-size: 14px;
    }
    .container-size.v-text-field > .v-input__control > .v-input__slot:before {
        border-color: inherit;
        border-style: none !important;
        border-width: 0 0 0 0;
    }   
    .container-size.v-text-field > .v-input__control > .v-input__slot:after {
        background-color: currentColor;
        border-color: currentColor;
        border-style: none !important;
        border-width: 0 0 0 0;
        transform: scaleX(0);
    }
    .container-size.v-input.text-fields.v-text-field--full-width > .v-input__control > .v-input__slot{
        margin-top: 0 !important;
        width: 150px;
    }
    .customerform_addContainers.v-data-table .v-data-table__empty-wrapper {
        display: none;
    }

</style>
