<template>
    <div>
    <div class="mb-16 customerInputform" 
        v-if="isvalidkey==false && completechecking">
        <div 
            class="spinner" 
            style="display: flex; position: absolute; top: 400px; left: 644px;"
            v-if="getPoLoading"
        >
            <v-progress-circular
                :size="40"
                color="#0171a1"
                indeterminate
                v-if="getPoLoading"
            >
            </v-progress-circular>
        </div>
        <div class="flex header justify-lg-space-between">
            <div>
                <!-- <img v-if="logo === ''" src="@/assets/images/logo.svg" alt="" class="trucker-logo"/>
                <img v-else :src="bo+logo" alt="" class="trucker-logo"/> -->                
                <img src="@/assets/images/dispatchCustomerLogo.png">   

            </div>
            <div class="row px-3">
                <div class="col-md-12 pa-0">
                    <span class="float-end trucker">{{ trucker.trucker.name }}</span>
                </div>
                <div class="col-md-12 pa-0">
                    <div class="float-end trucker-sub">
                        <span>Phone: {{ trucker.trucker.phone_number !== null ? trucker.trucker.phone_number : "xxxxxxxxxx" }}</span>
                        <span class="ml-3">Email: {{ trucker.trucker.email !== null ?  trucker.trucker.email :  trucker.trucker.general_email }}</span>
                    </div>
                </div>
            </div>
        </div>

        <CustomerDeliveryOrderForm
            v-if="!hasSubmitted"
            :trucker="trucker"
            :carriers="carriers"
            :sizes="sizes"
            :hasSubmitted="hasSubmitted"
            @submitted="handleFormData"
        />

        <CustomerDeliveryOrderFormSubmitted
            v-else-if="hasSubmitted"
            :formdata="formdata"
            :carriers="carriers"
            @saveAnother="handleSaveAnother"
        />

    </div>

<div class="spinner mt-4 loading" v-if="startloading">
        <v-progress-circular
            :size="40"
            color="#0171a1"
            indeterminate
            v-if="getPoLoading"
        >
        </v-progress-circular>
    </div>

    <div v-if="isvalidkey==true && completechecking" >
        <div style="border-left:0px solid #9ad2ed;display:flex" class="invalidkey">
            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.0004 43.6004C33.9297 43.6004 43.6004 33.9297 43.6004 22.0004C43.6004 10.071 33.9297 0.400391 22.0004 0.400391C10.071 0.400391 0.400391 10.071 0.400391 22.0004C0.400391 33.9297 10.071 43.6004 22.0004 43.6004ZM22.0004 40.3609C11.8602 40.3609 3.63989 32.1406 3.63989 22.0004C3.63989 11.8602 11.8602 3.63989 22.0004 3.63989C32.1406 3.63989 40.3609 11.8602 40.3609 22.0004C40.3609 32.1406 32.1406 40.3609 22.0004 40.3609ZM21.9925 25.242C22.8125 25.2425 23.4906 24.6336 23.5984 23.8431L23.6133 23.6233L23.6211 11.7417C23.6217 10.8471 22.897 10.1215 22.0024 10.1209C21.1824 10.1203 20.5043 10.7293 20.3965 11.5198L20.3816 11.7396L20.3738 23.6212C20.3732 24.5157 21.098 25.2414 21.9925 25.242ZM22.0014 32.796C23.1925 32.796 24.1581 31.8304 24.1581 30.6392C24.1581 29.448 23.1925 28.4824 22.0014 28.4824C20.8102 28.4824 19.8446 29.448 19.8446 30.6392C19.8446 31.8304 20.8102 32.796 22.0014 32.796Z" fill="#D68A1A"></path></svg>
            <p style="font-size:14px;margin-left:10px;padding-top:12px">Unable to load the page, please check the customer form url if it is correct.</p>
        </div>       
    </div>

  
    </div>
</template>

<script>
import axios from 'axios';
import CustomerDeliveryOrderForm from '../components/DOForm/CustomerDeliveryOrderForm.vue';
import CustomerDeliveryOrderFormSubmitted from '../components/DOForm/CustomerDeliveryOrderFormSubmitted.vue';

const boURL = process.env.VUE_APP_PO_URL
export default {
    components: {
    CustomerDeliveryOrderForm,
    CustomerDeliveryOrderFormSubmitted
},
    data: () => ({
		startloading: false,
        completechecking:0,
        isvalidkey: false,
        hasSubmitted: false,
        companyKey: "",
        trucker: "",
        logo: "",
        bo: boURL,
        carriers: [],
        sizes: [],
        formdata: [],
        getPoLoading: false
    }),
    watch: {
        // companyKey() {
        //     this.getTrucker()
        // }
    },
    methods: {
geturl(){
            return document.location.origin + '/do-form/'+this.$route.params.id
        },
        // async getTrucker() {
        //     await axios.get(`${process.env.VUE_APP_PO_URL}/trucker-details/${this.companyKey}`)
        //         .then((response) => {
        //             this.trucker = response.data.trucker
        //             this.logo = response.data.logo

        //             this.getPoLoading = false
        //         })
        //         .catch((error) => {
        //             console.log(error)
        //             this.getPoLoading = false
        //         })
        // },
        async getCarriers() {
            await axios.get(`${process.env.VUE_APP_PO_URL}/get-newcarriers`)
                .then((response) => {
                    this.carriers = response.data.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        async getSizes() {
            await axios.get(`${process.env.VUE_APP_PO_URL}/get-sizes`)
                .then((response) => {
                    this.sizes = response.data.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        handleFormData(data) {
            window.localStorage.setItem('formdata', JSON.stringify(data))
            window.localStorage.setItem('hasSubmitted', true)
            this.hasSubmitted = true
            this.formdata = data
        },
        handleSaveAnother() {
            this.hasSubmitted = false
},
        async checkexternalkey(key){
             this.isvalidkey = false;
             await axios
                .get(`${process.env.VUE_APP_PO_URL}/checkexternalkey/`+key)
                .then((response) => {  
                     if(response.data == 0){                       
                        this.isvalidkey = true;
                     } else{
                        this.isvalidkey = false;
                        //this.trucker = (response.data)
                        this.trucker = response.data
                     }         
                    this.completechecking = 1;
                    this.startloading = false;  
                    this.getPoLoading = false                  
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
    mounted() {
        this.getPoLoading = true
this.startloading = true;
        let check = window.localStorage.getItem("hasSubmitted")
        let data = window.localStorage.getItem("formdata")

        if(check) {
            this.hasSubmitted = check
            this.formdata = JSON.parse(data)
        }

        this.companyKey = this.$route.params.id
this.checkexternalkey(this.$route.params.id);
        this.getCarriers()
        this.getSizes()
    }
};
</script>s

<style>
.trucker-logo {
    width: 100%;
    height: 80px;
    object-fit: contain;
}
.v-main__wrap {
    background: #F5F5F5;
    flex: 1 1 auto;
    max-width: 100%;
    position: relative;
}

.text-item-label {
    color: #819FB2;
    font-size: 12px;
    text-transform: capitalize;
    font-family: 'Inter-Bold', sans-serif;
    margin-bottom: 6px;
}

.header {
    width: 960px;
    margin: 0 auto;
    padding: 32px 0px;
}
.customerForm {
    width: 960px;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 4px;
}
.form-header-wrap {
    border-bottom: 1px solid #c5c5c540;
    width: 100%;
    padding: 24px;
}
.form-footer-wrap {
    border-top: 1px solid #c5c5c540;
    width: 100%;
    padding: 24px;
}

.doFormTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    color: #4A4A4A;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.doFormSubTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #6D858F;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
.trucker {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    text-align: right;
    color: #4A4A4A;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.trucker-sub {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    color: #4A4A4A;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.drop-field {
    width: 100%;
    align-items: center !important;
    border: 1px solid #b4cfe0;
    padding: 30px 360px 30px 360px;
    border-style: dashed;
    border-radius: 4px;
}

.radio-wrap {
    position: absolute;
    margin: 0px 0px 0px 4px;
    width: 20px;
    height: 20px;
}
.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 18px;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
}
.control--radio .control__indicator {
  border-radius: 50%;
}
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  background: #ccc;
}
.control input:checked ~ .control__indicator {
  background: #2aa1c0;
}
.radio-box {
    padding: 10px 12px;
    gap: 8px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #B4CFE0;
    border-radius: 4px;
}
.radio-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4A4A4A;
    line-height: 22px;
    display: flex;
}
.radio-icon {
    margin: 0px 0px 0px 5px;
}


.v-input__control {
    /* height: 40px; */
    background: white;
}

.customerInputform div.v-input__slot {
    min-height: 40px !important;
    max-height: 40px !important;
    width: 100%;
}

.v-input__append-inner {
    margin-top: 1px;
}

.ti-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 4px;
    gap: 16px;
    width: 446px;
    background: #FFFFFF;
    border: 1px solid #B4CFE0 !important;
    border-radius: 4px;
    order: 1;
    /* height: 40px; */
}

div[role="combobox"] {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 13px;
    background: rgb(255, 255, 255);
    border: 1px solid #B4CFE0 !important;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: none !important;
}

input::placeholder {
    color: #b4cfe0 !important;
}

textarea::placeholder {
    color: #b4cfe0 !important;
}

.textarea-field {
    flex-direction: column;
    align-items: flex-start;
    height: 76px;
    background: #FFFFFF;
    border: 1px solid #B4CFE0;
    border-radius: 4px;
    font-size: 14px;
    padding: 10px 17px;
    color: #4A4A4A;
    caret-color: #0171A1 !important;
    width: 100%;
}
.textarea-field:focus {
    outline: 1px solid #B4CFE0;
}

.v-chip--select {
    background: transparent !important;
}

thead.v-data-table-header {
    background: #F7F7F7;
}
div.add-containers-table > div.v-data-table__wrapper > table > thead > tr > th[role="columnheader"] {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #6D858F !important;
    height: 38px;
    border-bottom:1px solid #EBF2F5 !important;
}
div.containers-table > div.v-data-table__wrapper > table > thead > tr > th[role="columnheader"] {
    /* background: #FFFFFF; */
    font-size: 12px !important;
    font-weight: 600 !important;
    color: #6D858F;
}
div.containers-table > div.v-data-table__wrapper > table > tbody > tr > td {
    font-weight: 400;
    font-size: 14px !important;
    line-height: 20px;
    color: #4A4A4A;
}
.customerInputform tbody > tr > td {
    align-items: center;
    padding: 8px 12px;
    background: #FFFFFF;
    border: 1px solid #EBF2F5;
}
.customerInputform tbody > tr > td.hide-container {
    align-items: center;
    padding: 8px 12px;
    background: #FFFFFF;
    border: none !important;
}
.add-container {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #0171A1;
    cursor: pointer;
    height: 36px !important;
}
.submit-btn {
    padding: 10px 16px;
    background: #0171A1;
    border-radius: 4px;
    color: white;
}
.submit-btn:disabled {
    padding: 10px 16px;
    background: #2980b9 !important;
    border-radius: 4px;
    color: white;
}
.submit--detail {
    padding: 10px 16px;
    border-radius: 4px;
    border: 1px solid #B4CFE0;
    color: #0171A1;
    font-weight: 500;
}
.cancel-btn {
    padding: 10px 16px;
    background: white;
    border-radius: 4px;
    border: 1px solid #B4CFE0;
}
.add-btn {
    cursor: pointer;
    background: #0171A1;
    color: white;
    padding: 2px 8px 6px 8px;
    border-radius: 50%;
}
.add-btn:hover {
    cursor: pointer;
    background: #2980b9;
    color: white;
    padding: 3px 9px 5px 8px;
    border-radius: 50%;
}
.displayItem {
    width: 100% !important;
    padding: 0px !important;
    display: flex;
}
.rowItemUploaded {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 24px;
    background: #FFFFFF;
    border: 1px dashed #B4CFE0;
    border-radius: 4px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
.IconUpload {
    padding: 13px 12px 8px 13px;
    background: #0171A1 !important;
    border-radius: 99px !important;
    color: white;
}
.replace-btn {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #0171a1;
    margin: 7px 68px;
    position: absolute;
}
.btn-blue {
    background-color: #0171A1 !important;
    color: #ffffff !important;
    padding: 10px 16px !important;
    font-size: 14px;
    height: 40px !important;
    text-transform: capitalize;
    letter-spacing: 0;
    box-shadow: none !important;
    border-radius: 4px;
    font-family: "Inter-Regular", sans-serif;
}
.btn-white {
    background-color: #ffffff !important;
    color: #0171A1 !important;
    border: 1px solid #B4CFE0 !important;
    padding: 10px 16px !important;
    font-size: 14px;
    height: 40px !important;
    text-transform: capitalize;
    letter-spacing: 0;
    box-shadow: none !important;
    border-radius: 4px;
    font-family: "Inter-Regular", sans-serif;
}
.flex {
    align-items: center;
    display: flex;
}
.justify-md-space-between {
    justify-content: space-between !important;
}
div.v-date-picker-table > table > tbody > tr > td {
    align-items: center;
    padding: 2px !important;
    background: #FFFFFF;
    border: none !important;
}
div.v-data-table__wrapper > table > tbody > tr > td {
    padding: 4px !important;
}
.v-text-field {
    padding-top: 0px;
    margin-top: 4px;
}
td > div > div.v-input__control > div[role="combobox"].v-input__slot {
    border: none !important;
    background: transparent !important;
    margin-top: 2px;
}

/* div.text-fields > div.v-input__control {
    /* display: flex;
    flex-direction: row; 
} */

.v-input.text-fields .v-text-field__details {
    display: contents;
}
.text-red {
    color: red
}

.invalidkey {
    width: 46%; 
    margin: 0px auto; 
    text-align: left; 
    padding: 50px;
    background: #fff;
    margin-top: 112px;
    border-radius: 14px;
    box-shadow: 0px 3px 11px 2px #ebf2f5;
}

.loading {
    width: 600px; 
    margin: 0px auto; 
    text-align: center; 
    padding: 50px;
    margin-top: 112px;
    border-radius: 14px;
}

.customerInputform .tags-email-wrapper input{
    padding-top:3px;
    padding-bottom:3px;
}
</style>
